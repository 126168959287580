import { useState } from 'react';

import { camelCase } from 'camel-case';
import { Plus } from 'lucide-react';
import { observer } from 'mobx-react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { AtomReference } from 'shared';
import { RowData } from 'shared/src/atom/sources/database/row.atom';
import { ResolvedVariableType } from 'shared/src/atom/variables';
import {
  CellDataType,
  Columns,
  Database as CreateDatabaseDTO
} from 'shared/src/database/database.schema';
import { TraceKeyMode } from 'shared/src/other/traceKey.schema';

import useProcess from '@hooks/useProcess';
import useStores from '@hooks/useStore';

import { AtomModel } from '@models/atom.model';

import ActionButton from '@atoms/button';
import { InputField } from '@atoms/input';
import { BasicModal } from '@atoms/modal';

import { LoaderBox, LoaderContainer } from '@/components/ui/loader';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { ParamsList } from '@/routes/routes.types';
import nanoID from '@/utils/nanoID';
import { Button, CircularProgress, DialogActions, IconButton } from '@mui/joy';

import {
  entitiesRowData,
  squadsRowData,
  teamsRowData,
  uniqueBuidRowData,
  uniqueProgramsRowData
} from './clientsData';
import { DatabasesContainer } from './database.style';

const DEFAULT_PROGRAMS_COLUMNS: Columns = [
  {
    field: 'name',
    cellDataType: CellDataType.Text,
    context: {
      isPrimaryKey: true
    }
  },
  {
    field: 'proportional',
    cellDataType: CellDataType.Boolean
  }
];

const DEFAULT_ENTITIES_COLUMNS: Columns = [
  {
    field: 'label',
    cellDataType: CellDataType.Text,
    context: {
      isPrimaryKey: true,
      editableAfterGeneration: false,
      regex: '^[a-zA-Z]+$'
    }
  },
  {
    field: 'name',
    cellDataType: CellDataType.Text
  },
  {
    field: 'programs',
    cellDataType: CellDataType.Object,
    context: {
      reference: {
        databaseId: 'ZH8kt56'
      }
    }
  },
  {
    field: 'BUIDS',
    cellDataType: CellDataType.Object,
    context: {
      reference: {
        databaseId: 'RjtRTwz'
      }
    }
  }
];

const DEFAULT_BUIDS_COLUMNS: Columns = [
  {
    field: 'BUID',
    cellDataType: CellDataType.Text,
    context: {
      isPrimaryKey: true
    }
  }
];

const DEFAULT_TEAMS_COLUMNS: Columns = [
  {
    field: 'code',
    cellDataType: CellDataType.Text,
    context: {
      isPrimaryKey: true
    }
  },
  {
    field: 'name',
    cellDataType: CellDataType.Text,
    context: {
      regex: '^(?!.*").*$'
    }
  }
];

const DEFAULT_SQUADS_COLUMNS: Columns = [
  {
    field: 'name',
    cellDataType: CellDataType.Text,
    context: {
      isPrimaryKey: true,
      regex: '^(?!.*").*$'
    }
  },
  {
    field: 'team',
    cellDataType: CellDataType.Object,
    context: {
      reference: {
        databaseId: 'TO_REPLACE'
      }
    }
  }
];

const Databases = () => {
  const navigate = useNavigate();
  const databaseId = useParams<string>()[ParamsList.DatabaseId];
  const process = useProcess();
  const { databaseStore, atomStore } = useStores();
  const [showNewRepoInput, setShowNewRepoInput] = useState(false);
  const [newDatabaseName, setNewDatabaseName] = useState('');
  const [firstDataModalOpen, setFirstDataModalOpen] = useState<boolean>(false);
  let currentTab: string = '';

  if (!process) {
    return (
      <LoaderContainer>
        <LoaderBox>
          <CircularProgress />
        </LoaderBox>
      </LoaderContainer>
    );
  }

  const onTabChange = (newTabId: string) => {
    currentTab = newTabId;
    navigate(`${newTabId}`);
  };

  const databases = process.databases.map((database) => {
    return {
      id: database.id,
      getName: database.getName
    };
  });

  if (databases.length == 0 && databaseId) {
    setTimeout(() => {
      navigate('');
    }, 100);
  }

  if (databases.length > 0 && !databaseId) {
    setTimeout(() => {
      onTabChange(databases[0].id);
    }, 100);
  }

  if (databaseId) {
    currentTab = databaseId;
  }

  const isUserOwner = () => {
    return process.permission.can_delete;
  };

  const createNewRowAtom = (gridAtomId: string, data: RowData) => {
    const newRowAtom = atomStore.createAtom(
      data.rowAtomId,
      'Row',
      data,
      {
        source: {
          elementId: gridAtomId,
          parentId: gridAtomId,
          parentKind: 'database'
        }
      },
      process.id,
      {
        resolvedType: 'Row' as ResolvedVariableType.Row
      }
    );

    return newRowAtom;
  };

  // TODO: replace this dingz with sdk
  const createNewDatabase = async (name: string) => {
    console.log('Creating new database: ', name);

    setShowNewRepoInput(false);
    setFirstDataModalOpen(false);

    if (!newDatabaseName) return;

    const newGridAtomId = nanoID();

    const columns =
      name === 'Entities'
        ? DEFAULT_ENTITIES_COLUMNS
        : name === 'Programs'
          ? DEFAULT_PROGRAMS_COLUMNS
          : name === 'BUIDS'
            ? DEFAULT_BUIDS_COLUMNS
            : name === 'Teams'
              ? DEFAULT_TEAMS_COLUMNS
              : name === 'Squads'
                ? DEFAULT_SQUADS_COLUMNS
                : DEFAULT_ENTITIES_COLUMNS;

    let teamsDatabaseId: string | undefined;

    if (name === 'Squads') {
      const teamsDatabase = databaseStore
        .toArray()
        .find((database) => database.getName === 'Teams');
      if (!teamsDatabase) {
        console.log('Teams database not found in store, will not add squads');
        return;
      }

      if (!columns[1]?.context?.reference) {
        console.log(
          'Reference not found in squads columns, will not add squads'
        );
        return;
      }
      teamsDatabaseId = teamsDatabase.id;
      columns[1].context.reference.databaseId = teamsDatabaseId;
    }

    const newDatabaseDto: CreateDatabaseDTO = {
      id: newGridAtomId,
      name: newDatabaseName,
      columnDefinitions: columns,
      processId: process?.id ?? '',
      rowReferences: [],
      traceKey: {
        value: camelCase(newDatabaseName),
        mode: TraceKeyMode.Follow
      },
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      deletedAt: null
    };

    const newGridModel = await databaseStore.createNewDatabase(newDatabaseDto);

    if (!newGridModel) {
      return;
    }

    databases.push(newGridModel);
    process.setDatabaseIds([...process.databaseIds, newGridModel.id]);

    setNewDatabaseName('');

    // name: 'AXA France',
    //   programs: [
    //     {
    //       dataItemId: 'vWNxq7t',
    //       blockType: DTRBlocks.Row,
    //       sourceId: 'CjpIlRM'
    //     }
    //   ]

    // name: 'AXA XL',
    //   proportional: true

    if (name === 'Programs') {
      for (const program of uniqueProgramsRowData) {
        const newRowAtomId = nanoID();
        const rowData: RowData = {
          rowAtomId: newRowAtomId,
          name: program.name,
          proportional: program.proportional
        };

        const newRowAtom = createNewRowAtom(newGridAtomId, rowData);

        await new Promise((r) => setTimeout(r, 100));

        if (!newRowAtom) return;

        newGridModel.addRowReference({
          dataItemId: newRowAtom.id,
          blockType: 'Row',
          sourceId: newGridAtomId
        });
        await new Promise((r) => setTimeout(r, 100));
      }
    } else if (name === 'BUIDS') {
      for (const buid of uniqueBuidRowData) {
        const newRowAtomId = nanoID();
        const rowData: RowData = {
          rowAtomId: newRowAtomId,
          BUID: buid.BUID
        };

        const newRowAtom = createNewRowAtom(newGridAtomId, rowData);
        await new Promise((r) => setTimeout(r, 100));

        if (!newRowAtom) return;

        newGridModel.addRowReference({
          dataItemId: newRowAtom.id,
          blockType: 'Row',
          sourceId: newGridAtomId
        });
        await new Promise((r) => setTimeout(r, 100));
      }
    } else if (name === 'Entities') {
      for (const entity of entitiesRowData) {
        const newRowAtomId = nanoID();
        const rowData: RowData = {
          rowAtomId: newRowAtomId,
          name: entity.name,
          label: entity.label
        };

        const newRowAtom = createNewRowAtom(newGridAtomId, rowData);
        await new Promise((r) => setTimeout(r, 100));

        if (!newRowAtom) return;

        newGridModel.addRowReference({
          dataItemId: newRowAtom.id,
          blockType: 'Row',
          sourceId: newGridAtomId
        });
        await new Promise((r) => setTimeout(r, 100));
      }
    } else if (name === 'Teams') {
      for (const team of teamsRowData) {
        const newRowAtomId = nanoID();
        const rowData: RowData = {
          rowAtomId: newRowAtomId,
          name: team.name,
          code: team.code
        };

        const newRowAtom = createNewRowAtom(newGridAtomId, rowData);
        await new Promise((r) => setTimeout(r, 100));

        if (!newRowAtom) return;

        newGridModel.addRowReference({
          dataItemId: newRowAtom.id,
          blockType: 'Row',
          sourceId: newGridAtomId
        });
        await new Promise((r) => setTimeout(r, 100));
      }
    } else if (name === 'Squads') {
      for (const squad of squadsRowData) {
        const newRowAtomId = nanoID();

        const teamDatabase = databaseStore.get(teamsDatabaseId);
        if (!teamDatabase) {
          console.log(
            `Teams database not found in store, will not add squad ${squad.name}`
          );
          continue;
        }

        const teamRow = teamDatabase
          .getRowAtoms()
          .find(
            (atom: AtomModel<RowData>) => atom.data['code'] === squad.team[0]
          );
        if (!teamRow) {
          console.log(
            `Team row for squad ${squad.name}, code: ${squad.team[0]} not found in store, you will need to add it manually`
          );
        }

        const teamRowAtomRef: AtomReference | undefined = teamRow
          ? {
              dataItemId: teamRow.id,
              blockType: 'Row',
              sourceId: teamDatabase.id
            }
          : undefined;

        const rowData: RowData = {
          rowAtomId: newRowAtomId,
          name: squad.name,
          team: teamRowAtomRef ? [teamRowAtomRef] : []
        };

        const newRowAtom = createNewRowAtom(newGridAtomId, rowData);
        await new Promise((r) => setTimeout(r, 100));

        if (!newRowAtom) {
          console.log(
            `New row atom for squad ${squad.name} not found, will not add this squad`
          );
          continue;
        }

        if (teamRowAtomRef) {
          newRowAtom.syncRowReferences([teamRowAtomRef], teamDatabase.id);
          await new Promise((r) => setTimeout(r, 100));
        }

        newGridModel.addRowReference({
          dataItemId: newRowAtom.id,
          blockType: 'Row',
          sourceId: newGridAtomId
        });
        await new Promise((r) => setTimeout(r, 100));
      }
    }

    onTabChange(newGridModel.id);
  };

  const handleNewRepoKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
    name: string
  ) => {
    if (event.key === 'Enter' || event.key === 'Tab') {
      void createNewDatabase(name);
    } else if (event.key == 'Escape') {
      setShowNewRepoInput(false);
    }
  };

  return (
    <>
      <DatabasesContainer>
        {databases.length == 0 ? (
          <ActionButton
            value="Create your first database"
            onClick={() => setFirstDataModalOpen(true)}
            sx={{
              width: '250px',
              alignSelf: 'center',
              justifySelf: 'center',
              marginTop: '20px'
            }}
          />
        ) : (
          <Tabs
            value={currentTab}
            onValueChange={onTabChange}
            className="h-full pl-2"
          >
            {databases.map((database) => (
              <TabsContent
                value={database.id}
                key={database.id}
                className="h-88 -mt-0.5"
              >
                <Outlet />
              </TabsContent>
            ))}
            <TabsList className="flex flex-wrap items-center justify-start bg-transparent mt-2">
              {databases.map((databaseModel) => (
                <TabsTrigger value={databaseModel.id} key={databaseModel.id}>
                  {databaseModel.getName}
                </TabsTrigger>
              ))}
              {isUserOwner() &&
                (!showNewRepoInput ? (
                  <IconButton
                    variant="plain"
                    color="neutral"
                    size="sm"
                    onClick={() => setShowNewRepoInput(true)}
                    sx={{
                      maxWidth: '100px',
                      marginLeft: '5px'
                    }}
                  >
                    <Plus />
                  </IconButton>
                ) : (
                  <InputField
                    placeholder="Entities"
                    onChange={(e) => setNewDatabaseName(e.target.value)}
                    value={newDatabaseName}
                    width="100px"
                    onBlur={() => setShowNewRepoInput(false)}
                    onKeyDown={(event) =>
                      handleNewRepoKeyDown(event, newDatabaseName)
                    }
                    sx={{
                      marginLeft: 1
                    }}
                  />
                ))}
            </TabsList>
          </Tabs>
        )}
      </DatabasesContainer>
      <BasicModal
        open={firstDataModalOpen}
        setOpen={setFirstDataModalOpen}
        title="Give it a name"
        width="400px"
      >
        <InputField
          placeholder="Entities"
          onChange={(e) => setNewDatabaseName(e.target.value)}
          value={newDatabaseName}
          width="200px"
        />
        <DialogActions>
          <Button
            variant="solid"
            color="primary"
            size="sm"
            disabled={newDatabaseName.length == 0}
            onClick={() => void createNewDatabase(newDatabaseName)}
            sx={{
              maxWidth: '100px'
            }}
          >
            Create
          </Button>
        </DialogActions>
      </BasicModal>
    </>
  );
};

export default observer(Databases);
