import { useEffect, useState } from 'react';

import { Info } from 'lucide-react';
import { observer } from 'mobx-react';

import { DeleteDialogContent } from '@components/dialogs/delete.dialog';
import { Button } from '@components/ui/button';
import { Dialog, DialogTrigger } from '@components/ui/dialog';
import {
  SettingHeader,
  SettingsContainer,
  SettingsContent
} from '@components/ui/settings';

import useProcess from '@hooks/useProcess';
import useStores from '@hooks/useStore';

import { ProcessModel } from '@models/process.model';

import { InputField } from '@atoms/input';
import { SelectField } from '@atoms/select';

import { LoaderBox, LoaderContainer } from '@/components/ui/loader';
import { ProcessImageTag } from '@/types/process.types';
import { ENV, EnvironmentName } from '@/utils/constants';
import { CircularProgress, FormHelperText, Option } from '@mui/joy';

const GeneralSetting = () => {
  const process = useProcess();
  const [processName, setProcessName] = useState(process?.name);
  const [emptyProcessNameError, setEmptyProcessNameError] = useState(false);

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const { processStore, actionStore, transitionStore, workflowStore } =
    useStores();

  useEffect(() => {
    setProcessName(process?.name);
  }, [process]);

  if (!process)
    return (
      <LoaderContainer>
        <LoaderBox>
          <CircularProgress />
        </LoaderBox>
      </LoaderContainer>
    );

  const handleTagChange = (
    _: React.SyntheticEvent | null,
    newValue: string | null
  ) => {
    if (
      !newValue ||
      !Object.values(ProcessImageTag).includes(newValue as ProcessImageTag)
    ) {
      return;
    }
    process.imageTag = newValue as ProcessImageTag;
    void updateProcessImage(process.image);
  };

  const canDelete = process.permission.can_delete;

  const updateProcessImage = async (value: ProcessModel['image']) => {
    return processStore.updateProcess(process?.id, {
      image: value
    });
  };

  const onProcessNameBlur = async () => {
    if (!processName) {
      return setEmptyProcessNameError(true);
    } else if (emptyProcessNameError) {
      setEmptyProcessNameError(false);
    }
    await processStore.updateProcess(process.id, {
      name: processName
    });
    process.setName(processName);
  };

  async function deleteProcess() {
    if (!process?.id) return;
    const response = await processStore.deleteProcess(process.id);
    if (response) {
      window.location.assign('/');
    }
  }

  return (
    <SettingsContainer>
      <SettingHeader
        title="General Settings"
        description="Customize your processus"
      />
      <SettingsContent>
        <label className="flex flex-col mb-7 text-sm font-normal">
          <InputField
            label="Processus name"
            placeholder="My beatiful processus"
            onChange={(e) => setProcessName(e.target.value)}
            value={processName}
            onBlur={() => void onProcessNameBlur()}
            error={emptyProcessNameError}
          />
          {emptyProcessNameError && (
            <FormHelperText>
              <Info size={16} />
              Process name can't be empty
            </FormHelperText>
          )}

          {process?.image && (
            <div className="flex gap-4 items-end pt-4">
              <InputField
                label="Image"
                placeholder="git.sia-partners.com:5656/..."
                disabled
                value={process.imageUrl}
                width="80%"
              />

              <SelectField
                label="Tag"
                variant="outlined"
                placeholder="my-super-tag"
                defaultValue={process.imageTag ?? undefined}
                onChange={handleTagChange}
                disabled={ENV == EnvironmentName.Release}
                width="20%"
              >
                {Object.values(ProcessImageTag).map((tag) => (
                  <Option value={tag} key={tag}>
                    {tag}
                  </Option>
                ))}
              </SelectField>
            </div>
          )}
        </label>

        <footer className="flex w-full justify-end mt-5">
          <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
            <DialogTrigger>
              <Button variant="destructive" disabled={!canDelete}>
                Delete the project
              </Button>
            </DialogTrigger>
            <DeleteDialogContent
              setOpen={setIsDialogOpen}
              stats={{
                actionCount: actionStore.data.size,
                transitionCount: transitionStore.data.size,
                workflowCount: workflowStore.data.size
              }}
              isDraft={process.draft}
              nuke={deleteProcess}
            />
          </Dialog>
        </footer>
      </SettingsContent>
    </SettingsContainer>
  );
};

export default observer(GeneralSetting);
