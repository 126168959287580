import { motion } from 'framer-motion';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';

import { MonacoEditor } from '@components/monaco/monacoEditor.code';

import useAction from '@hooks/useAction';
import useProcess from '@hooks/useProcess';
import useStore from '@hooks/useStore';

import { LoaderBox, LoaderContainer } from '@/components/ui/loader';
import { OnChange } from '@monaco-editor/react';
import { CircularProgress } from '@mui/joy';

export const CustomCodePage = observer(() => {
  const { codeStore } = useStore();
  const action = useAction();
  const process = useProcess();

  if (!process)
    return (
      <LoaderContainer>
        <LoaderBox>
          <CircularProgress />
        </LoaderBox>
      </LoaderContainer>
    );

  const codeModel = action?.codeModel;
  if (codeStore.lastCodeModel?.id !== codeModel?.id) {
    codeStore.lastCodeModel = codeModel;
  }

  codeStore.lastCodeModel = codeModel;

  if (!codeModel) return <></>;

  const onCodeChange: OnChange = (value) => {
    if (!value) return;
    codeModel.code = value;
  };

  return (
    <motion.div
      className="z-40 w-full h-full bg-[#fafafa]"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      <MonacoEditor
        modelId={action.traceKey.value}
        preCode={toJS(codeModel.getPreCode())}
        code={toJS(codeModel.code)}
        onCodeChange={onCodeChange}
        handleMonacoErrors={(monacoErrors) => codeModel.setErrors(monacoErrors)}
      />
    </motion.div>
  );
});
