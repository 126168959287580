import { makeObservable, observable, runInAction } from 'mobx';
import {
  Collaborator,
  DraftProcess,
  GenericStudioProcess,
  GenericStudioProcessSchema,
  HydratedGeneration,
  SmallProcessSchema,
  StratumnProcess
} from 'shared';
import { z } from 'zod';

import { roleToPermissions } from '@components/share/utils';

import { DatabaseModel } from '@models/database.model';
import { GenerationModel } from '@models/generation.model';
import { ProcessModel } from '@models/process.model';

import AsyncStore from '@stores/base/async.store';
import RootStore from '@stores/root.store';

import { newError } from '@/services/errors/errors';
import { TooManyRequestsError } from '@/services/errors/studio-errors';
import { UserRoles } from '@/types/process.types';
import { ApiResponseEmpty } from '@/utils/http';
import { parseWithZod } from '@/utils/parseZodSchema';

export interface InviteUserDTO {
  role: UserRoles;
  emails: string[];
}

export interface InviteUsersResponseDTO {
  users: Collaborator[];
}
export interface UpdateRoleDTO {
  role: UserRoles;
  userId: string;
}

export interface UpdateProcessDTO {
  password?: string;
  is_public?: boolean;
  name?: string;
  image?: string;
}

export interface FetchProcessOptions {
  password?: string;
}

// TODO: this should be a zod schema declared in shared and also used in the backend
export type CreateProcessDto = {
  name: string;
} & (
  | { draft: false; bpmnWorkflow?: string }
  | { draft: true; template?: string }
);

export class ProcessStore extends AsyncStore<ProcessModel> {
  private fetchingProcesses: Set<string> = new Set();
  private fetchingGenerationPages: Map<string, Set<number>> = new Map();

  constructor(rootStore: RootStore) {
    super(rootStore, ProcessModel, 'process');

    makeObservable<ProcessStore, 'fetchingGenerationPages'>(this, {
      fetchingGenerationPages: observable
    });
  }

  public async getorFetchFullProcess(
    id: ProcessModel['id'],
    fetchOptions?: FetchProcessOptions
  ): Promise<ProcessModel | undefined> {
    const cachedProcess = this.get(id);
    if (cachedProcess && !cachedProcess.isSmallProcess) return cachedProcess;

    let parsedProcess: GenericStudioProcess | undefined;
    try {
      parsedProcess = await this.fetchAndParseProcess(id, fetchOptions);
    } catch (error) {
      newError('PROCS-gjH3f', error);
      throw error;
    }

    if (!parsedProcess) return;

    const newProcess = this.loadParsedProcess(parsedProcess, fetchOptions);

    if (!newProcess) return;

    this.loadCommonStores(newProcess, parsedProcess);

    if (parsedProcess.draft) {
      this.loadDraftStores(newProcess, parsedProcess);
    } else {
      this.loadStratumnStores(newProcess, parsedProcess);
    }

    return newProcess;
  }

  private loadCommonStores(
    process: ProcessModel,
    parsedProcess: GenericStudioProcess
  ) {
    const databaseStore = this.rootStore.databaseStore;
    const atomStore = this.rootStore.atomStore;
    const generationStore = this.rootStore.generationStore;

    atomStore.loadAtoms(parsedProcess.atom);

    const loadedDatabases: DatabaseModel[] = [];
    for (const database of parsedProcess.databases) {
      const loadedDatabase = databaseStore.loadDatabaseToStore(database);
      if (loadedDatabase) loadedDatabases.push(loadedDatabase);
    }
    process.setDatabaseIds(loadedDatabases.map((db) => db.id));

    for (const generation of parsedProcess.generations) {
      generationStore.loadParsedGeneration(generation);
    }

    process.generationPageStore.setTotalNumberOfItemsInDB(
      parsedProcess.generationsMetadata.numberOfGenerations
    );
    process.generationPageStore.setPage(
      1,
      parsedProcess.generations.map((g) => g.id)
    );
  }

  private loadDraftStores = (
    process: ProcessModel,
    draftProcess: DraftProcess
  ) => {
    const workflowStore = this.rootStore.workflowStore;
    const workflowIds: string[] = [];

    for (const workflow of draftProcess.workflows) {
      const loadedWf = workflowStore.loadBaseWorkflow(workflow);
      workflowIds.push(loadedWf.id);
    }

    process.setWorkflowIds(workflowIds);
  };

  private loadStratumnStores = (
    process: ProcessModel,
    stratumnProcess: StratumnProcess
  ) => {
    const workflowStore = this.rootStore.workflowStore;
    const transitionStore = this.rootStore.transitionStore;
    const notificationTemplateStore = this.rootStore.notificationTemplateStore;

    /* ------------------------- Handle workflows ------------------------- */
    const workflowIds = workflowStore.loadWorkflows(stratumnProcess.workflows);
    process.setWorkflowIds(workflowIds);

    /* --------------------------- Handle transitions --------------------------- */
    const loadedTransitions = transitionStore.loadTransitions(
      stratumnProcess.Transition
    );
    process.setTransitionIds(
      loadedTransitions.filter((t) => t !== undefined).map((t) => t.id)
    );

    /* -------------------------- Handle notification templates ------------------*/
    const loadedNotificationTemplates =
      notificationTemplateStore.loadNotificationTemplates(
        stratumnProcess.notificationTemplates
      );
    process.setNotificationTemplateIds(
      loadedNotificationTemplates
        ?.filter((nt) => nt !== undefined)
        .map((nt) => nt.id) ?? []
    );
  };

  private async fetchAndParseProcess(
    id: ProcessModel['id'],
    fetchOptions?: FetchProcessOptions
  ): Promise<GenericStudioProcess | undefined> {
    if (this.fetchingProcesses.has(id)) return;

    this.fetchingProcesses.add(id);

    const fetchedProcess = await this.httpWrapper
      .get<GenericStudioProcess>(`/${id}`, {
        headers: {
          'x-studio-password': fetchOptions?.password
        }
      })
      .catch((error) => {
        newError('PROCS-VYudv', error);
        this.fetchingProcesses.delete(id);
        throw error;
      });

    this.fetchingProcesses.delete(id);

    if (!fetchedProcess) {
      newError('PROCS-cYUWH', `Failed to fetch process ${id}`);
      return;
    }

    const parsedProcess = this.parseFetchedProcess(fetchedProcess);

    return parsedProcess;
  }

  private parseFetchedProcess(
    fetchedProcess: unknown
  ): GenericStudioProcess | undefined {
    const parsedProcess = parseWithZod(
      GenericStudioProcessSchema,
      fetchedProcess,
      'PROCS-veX4f'
    );

    return parsedProcess;
  }

  private loadParsedProcess(
    parsedProcess: GenericStudioProcess,
    fetchOptions?: FetchProcessOptions
  ): ProcessModel | undefined {
    const newProcess = new ProcessModel(
      this,
      parsedProcess.id,
      parsedProcess.name,
      false,
      parsedProcess.icon,
      parsedProcess.published,
      parsedProcess.permission,
      parsedProcess.collaborators,
      parsedProcess.is_public,
      parsedProcess.updatedAt,
      parsedProcess.createdAt,
      parsedProcess.image ?? '',
      parsedProcess.draft,
      fetchOptions?.password,
      parsedProcess.icon.includes('http') ? parsedProcess.icon : undefined,
      parsedProcess.deletedAt ? parsedProcess.deletedAt : undefined
    );

    this.set(parsedProcess.id, newProcess);

    return newProcess;
  }

  public async generateProcess(
    processId: ProcessModel['id']
  ): Promise<GenerationModel | undefined> {
    const cachedProcess = this.get(processId);

    if (!cachedProcess) {
      newError(
        'PROCS-y2MxQ',
        `Process ${processId} not found in cache while generating`
      );
      throw new Error(
        `Process ${processId} not found in cache while generating`
      );
    }

    const response = await this.httpWrapper
      .get<HydratedGeneration>(`/${processId}/generate`, {
        headers: {
          'x-studio-password': cachedProcess.password
        }
      })
      .catch((error) => {
        if (error instanceof TooManyRequestsError) {
          return Promise.reject(
            new Error(
              'You can only trigger a new generation 3 times per minute'
            )
          );
        }
        return Promise.reject(error);
      });

    const parsedGeneration =
      this.rootStore.generationStore.parseFetchedGeneration(response);

    if (!parsedGeneration) return;

    const loadedGeneration =
      this.rootStore.generationStore.loadParsedGeneration(parsedGeneration);

    if (!loadedGeneration) return;

    cachedProcess.generationPageStore.preprendItem(1, loadedGeneration.id);

    cachedProcess.generationPageStore.setTotalNumberOfItemsInDB(
      cachedProcess.generationPageStore.getTotalNumberOfItemsInDB() + 1
    );

    return loadedGeneration;
  }

  public async updateProcess(
    id: Maybe<ProcessModel['id']>,
    data: UpdateProcessDTO
  ): Promise<ApiResponseEmpty> {
    const response = (await this.httpWrapper
      .patch(`/${id}`, data)
      .catch((error: Error) => {
        newError('PROCS-8Kc5L', error, true, {
          customMessage: 'An error occurred during updating the process'
        });
      })) as ApiResponseEmpty;

    const cachedProcess = this.get(id);

    if (!cachedProcess) {
      newError(
        'PROCS-8Kc5L',
        `Process ${id} not found in cache while updating process`
      );
      return response;
    }

    if (response) {
      runInAction(() => {
        if (data.password) cachedProcess.setPassword(data.password);
        if (data.is_public) cachedProcess.setIsPublic(data.is_public);
        if (data.name) cachedProcess.setName(data.name);
        if (data.image) cachedProcess.setImage(data.image);
      });
    }
    return response;
  }

  public async deleteProcess(id: ProcessModel['id']): Promise<boolean> {
    const cachedProcess = this.get(id);

    if (!cachedProcess) {
      newError(
        'PROCS-y2MxQ',
        `Process ${id} not found in cache while deleting process`
      );
      return false;
    }

    await this.httpWrapper.delete(`/${id}`, {
      headers: {
        'x-studio-password': cachedProcess.password
      }
    });

    return this.data.delete(id);
  }

  public async inviteUser(
    processId: string,
    emails: string[],
    role: UserRoles
  ): Promise<Maybe<Collaborator[]>> {
    const dto: InviteUserDTO = {
      emails,
      role
    };

    const cachedProcess = this.get(processId);

    if (!cachedProcess) {
      newError(
        'PROCS-y2MxQ',
        `Process ${processId} not found in cache while inviting users`
      );
      return;
    }

    const response = await this.httpWrapper
      .post<InviteUsersResponseDTO>(`/${processId}/invites`, dto, {
        headers: {
          'x-studio-password': cachedProcess.password
        }
      })
      .catch((error: Error) => {
        newError('PROCS-5m6gS', error, true, {
          customMessage:
            'An error occured, user is not part of Stratumn or already invited'
        });
      });

    if (response) {
      cachedProcess.setCollaborators([
        ...cachedProcess.collaborators,
        ...response.users
      ]);
      return response.users;
    }
    return;
  }

  public async loadRoles(id: string): Promise<Maybe<Collaborator[]>> {
    const cachedProcess = this.get(id);

    if (!cachedProcess) {
      newError(
        'PROCS-QJYgO',
        `Process ${id} not found in cache while loading roles`
      );
      return;
    }

    return await this.httpWrapper.get<Collaborator[]>(`/${id}/roles`, {
      headers: {
        'x-studio-password': cachedProcess.password
      }
    });
  }

  public async updateRole(
    id: ProcessModel['id'],
    role: UserRoles,
    userId: string
  ): Promise<void> {
    const dto: UpdateRoleDTO = {
      role,
      userId
    };
    await this.httpWrapper.patch(`/${id}/roles`, dto);

    const cachedProcess = this.get(id);

    if (!cachedProcess) {
      newError(
        'PROCS-QJYgO',
        `Process ${id} not found in cache while updating role`
      );
      return;
    }

    cachedProcess.updateCollaborator(userId, roleToPermissions(role));
  }

  public async deleteRole(
    id: ProcessModel['id'],
    userId: string
  ): Promise<void> {
    const cachedProcess = this.get(id);

    if (!cachedProcess) {
      newError(
        'PROCS-u6gv_',
        `Process ${id} not found in cache while deleting role`
      );
      return;
    }
    await this.httpWrapper.delete(`/${id}/roles/${userId}`, {
      headers: {
        'x-studio-password': cachedProcess.password
      }
    });

    cachedProcess.setCollaborators(
      cachedProcess.collaborators?.filter((collab) => collab.id !== userId)
    );
  }

  public async getAllProcesses() {
    const fetchedProcesses = await this.httpWrapper.get('/');

    const parsedSmallProcesses = parseWithZod(
      z.array(SmallProcessSchema),
      fetchedProcesses,
      'PROC-qok32n'
    );

    if (!parsedSmallProcesses) {
      return;
    }

    for (const process of parsedSmallProcesses) {
      if (this.get(process.id)) continue;

      const newProcess = new ProcessModel(
        this,
        process.id,
        process.name,
        true,
        '',
        process.published,
        process.permission,
        [],
        false,
        process.updatedAt,
        process.createdAt,
        '',
        process.draft,
        undefined,
        undefined,
        undefined
      );

      newProcess.setWorkflowIds(process.workflows.map((wf) => wf.id));

      this.set(process.id, newProcess);
    }
  }

  public async fetchGenerationsForProcess(process: ProcessModel, page: number) {
    if (this.isGenerationPageFetching(process.id, page)) return;

    this.setGenerationPageFetching(process.id, page, true);

    const take = process.generationPageStore.getItemsPerPage();

    const fetchedGenerations = await this.httpWrapper
      .get<
        HydratedGeneration[]
      >(`/${process.id}/generations?page=${page}&take=${take}`)
      .catch((error) => {
        newError('PROCS-ZDUoE', error);
        this.setGenerationPageFetching(process.id, page, false);
        return;
      });

    this.setGenerationPageFetching(process.id, page, false);

    if (!fetchedGenerations) {
      newError(
        'GNST-4PC08',
        `Failed to fetch page ${page} of generations for process ${process.id}`
      );
      return;
    }

    const parsedGenerations = fetchedGenerations.map((generation) =>
      this.rootStore.generationStore.parseFetchedGeneration(generation)
    );

    const filteredParsedGenerations = parsedGenerations.filter(
      (generation) => generation !== undefined
    );

    const loadedGenerations = filteredParsedGenerations.map((generation) =>
      this.rootStore.generationStore.loadParsedGeneration(generation)
    );

    process.generationPageStore.setPage(
      page,
      loadedGenerations.map((g) => g.id)
    );

    return loadedGenerations;
  }

  public isGenerationPageFetching(processId: string, page: number): boolean {
    return this.fetchingGenerationPages.get(processId)?.has(page) ?? false;
  }

  private setGenerationPageFetching(
    processId: string,
    page: number,
    isFetching: boolean
  ): void {
    if (!this.fetchingGenerationPages.has(processId)) {
      this.fetchingGenerationPages.set(processId, new Set());
    }
    const pages = this.fetchingGenerationPages.get(processId);
    if (!pages) return;

    isFetching ? pages.add(page) : pages.delete(page);
  }
}
