import { computed, makeObservable, observable } from 'mobx';
import { Collaborator, Permission } from 'shared';
import { z } from 'zod';

import Model, { ModelError } from '@models/base/base.model';

import { PageStore } from '@stores/base/page.store';
import { ProcessStore } from '@stores/process.store';

import { newError } from '@/services/errors/errors';
import { ProcessImageTag } from '@/types/process.types';

import { LocalDBModel } from './base/localDB.model';
import { DatabaseModel } from './database.model';
import { GenerationModel } from './generation.model';
import { WorkflowModel } from './workflow.model';

const localStorageSchema = z.object({
  workflowIds: z.array(z.string())
});

export class ProcessModel extends LocalDBModel<
  z.infer<typeof localStorageSchema>
> {
  public name: string;
  public icon: string;
  public published: boolean;
  public permission: Permission;
  public collaborators: Collaborator[];
  public is_public: boolean;
  public updatedAt: string;
  public createdAt: string;
  public image: string;
  public draft: boolean;
  public iconUrl: string =
    'https://storage.googleapis.com/speaknact_prod/users/avatars/avatar_1627425719_652.png';
  public deletedAt: Maybe<string>;
  public password: Maybe<string>;

  public isSmallProcess: boolean;

  /* ------------------------ Store-related properties ------------------------ */
  public workflowIds: string[];
  public databaseIds: string[];
  public transitionIds: string[];
  public notificationTemplateIds: string[];
  private generationsPageStore = new PageStore<GenerationModel>({
    totalNumberOfItemsInDB: 0,
    itemsPerPage: 7,
    baseStore: this.store.rootStore.generationStore
  });

  constructor(
    store: ProcessStore,
    id: Model['id'],
    name: string,
    isSmall: boolean,
    icon: string,
    published: boolean,
    permission: Permission,
    collaborators: Collaborator[],
    is_public: boolean,
    updatedAt: string,
    createdAt: string,
    image: string,
    draft: boolean,
    password?: string,
    iconUrl?: string,
    deletedAt?: string
  ) {
    super(store, id, localStorageSchema, false);

    this.name = name;
    this.icon = icon;
    this.published = published;
    this.permission = permission;
    this.collaborators = collaborators;
    this.is_public = is_public;
    this.updatedAt = updatedAt;
    this.createdAt = createdAt;
    this.image = image;
    this.draft = draft;
    this.password = password;
    this.deletedAt = deletedAt;
    this.isSmallProcess = isSmall;

    if (iconUrl) {
      this.iconUrl = iconUrl;
    }

    this.workflowIds = [];
    this.databaseIds = [];
    this.transitionIds = [];
    this.notificationTemplateIds = [];

    makeObservable(this, {
      name: observable,
      icon: observable,
      published: observable,
      permission: observable,
      collaborators: observable,
      is_public: observable,
      updatedAt: observable,
      image: observable,
      draft: observable,
      password: observable,
      iconUrl: observable,
      deletedAt: observable,
      workflows: computed
    });
  }

  /* ------------------------ Class properties setters ------------------------ */
  updateCollaborator(newCollaboratorId: string, permissions: Permission) {
    const foundCollaborator = this.collaborators.find(
      (collaborator) => collaborator.id === newCollaboratorId
    );
    if (!foundCollaborator) return;
    foundCollaborator.permissions = permissions;
  }

  setCollaborators(newCollaborators: Collaborator[]) {
    this.collaborators = newCollaborators;
  }

  setPassword(newPassword: string) {
    this.password = newPassword;
  }

  setIsPublic(newIsPublic: boolean) {
    this.is_public = newIsPublic;
  }

  setName(newName: string) {
    this.name = newName;
  }

  setImage(newImage: string) {
    this.image = newImage;
  }

  /* ----------------------------- Custom Getters ----------------------------- */
  get imageUrl() {
    return this.image?.split(':').slice(0, -1).join(':');
  }

  get imageTag() {
    const tag = this.image?.split(':').at(-1);
    if (!tag) return undefined;
    if (!Object.values(ProcessImageTag).includes(tag as ProcessImageTag))
      return undefined;
    return tag as ProcessImageTag;
  }

  get workflows(): WorkflowModel[] {
    const workflows: WorkflowModel[] = [];
    for (const workflowId of this.workflowIds) {
      const workflow = this.store.rootStore.workflowStore.get(workflowId);
      if (!workflow) {
        newError(
          'PROCM-lyQLF',
          `Workflow ${workflowId} not found while getting workflows for process ${this.id}, probably deleted`
        );
        continue;
      }
      workflows.push(workflow);
    }

    return workflows;
  }

  get databases(): DatabaseModel[] {
    const databases: DatabaseModel[] = [];
    for (const databaseId of this.databaseIds) {
      const database = this.store.rootStore.databaseStore.get(databaseId);
      if (!database) {
        newError(
          'PROCM-pVx19',
          `Database ${databaseId} not found while getting databases for process ${this.id}, probably deleted`
        );
        continue;
      }
      databases.push(database);
    }
    return databases;
  }

  get generationPageStore(): PageStore<GenerationModel> {
    return this.generationsPageStore;
  }

  get lastGenerations(): GenerationModel[] {
    return this.generationsPageStore.getPageItems(1).slice(0, 3);
  }

  get isFullProcess(): boolean {
    return !this.isSmallProcess;
  }

  get hasNoGenerations(): boolean {
    // TODO: remove isSmall this once we have a proper loading state
    return this.isFullProcess && this.lastGenerations.length === 0;
  }

  /* ----------------------------- Custom Setters ----------------------------- */
  set imageTag(newTag: ProcessImageTag | undefined) {
    if (!newTag) {
      return;
    }
    this.image = this.imageUrl?.concat(':').concat(newTag);
  }

  getLocalWorkflowIds() {
    return this.getLocal('workflowIds');
  }

  setWorkflowIds(newWorkflowIds: string[]) {
    this.workflowIds = newWorkflowIds;
    this.saveLocal('workflowIds', newWorkflowIds);
  }

  setDatabaseIds(newDatabaseIds: string[]) {
    this.databaseIds = newDatabaseIds;
  }

  setTransitionIds(newTransitionIds: string[]) {
    this.transitionIds = newTransitionIds;
  }

  setNotificationTemplateIds(newNotificationTemplateIds: string[]) {
    this.notificationTemplateIds = newNotificationTemplateIds;
  }

  public static getLocalWorkflowIds(processId: Maybe<string>) {
    return ProcessModel.getLocal<string[]>(
      'ProcessModel',
      processId,
      'workflowIds'
    );
  }

  /* ----------------------------- Abstract methods ----------------------------- */
  get toJSON() {
    return {
      name: this.name,
      is_public: this.is_public,
      password: this.password,
      image: this.image
    };
  }

  get errors(): ModelError[] {
    return [];
  }
}
