import { observer } from 'mobx-react';

import DNDBase from '@components/dnd/base';
import {
  SettingHeader,
  SettingsContainer,
  SettingsContent
} from '@components/ui/settings';

import useProcess from '@hooks/useProcess';

import ActionButton from '@atoms/button';

import { LoaderBox, LoaderContainer } from '@/components/ui/loader';
import { CircularProgress } from '@mui/joy';

const GlobalVariables = () => {
  const process = useProcess();

  const globalVariablesDnd = process?.workflows[0]?.globalVariablesDnd;

  if (!process)
    return (
      <LoaderContainer>
        <LoaderBox>
          <CircularProgress />
        </LoaderBox>
      </LoaderContainer>
    );

  const onAddVariable = () => {
    if (!globalVariablesDnd) return;
    const window = globalVariablesDnd.createDndBlock(
      'GlobalVariable',
      'unrelevant'
    );

    const dropZonePath = 'root:root|0';
    const newState = globalVariablesDnd.insertBlockInState(
      dropZonePath,
      globalVariablesDnd.state,
      window
    );

    globalVariablesDnd.setState(newState);
  };

  return (
    <SettingsContainer>
      <SettingHeader
        title="Global Variables"
        description="Customize the big summary table where all trace are referenced"
      />
      {globalVariablesDnd && (
        <SettingsContent>
          <div className="title-body">
            <h3>Your global variables</h3>
            <ActionButton value="Add a variable" onClick={onAddVariable} />
          </div>
          <DNDBase dnd={globalVariablesDnd} />
        </SettingsContent>
      )}
    </SettingsContainer>
  );
};

export default observer(GlobalVariables);
