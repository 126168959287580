import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';

import { SettingHeader, SettingsContainer } from '@components/ui/settings';
import { SettingsContent } from '@components/ui/settings';

import useProcess from '@hooks/useProcess';
import useStores from '@hooks/useStore';

import ActionButton from '@atoms/button';

import { LoaderBox, LoaderContainer } from '@/components/ui/loader';
import { CircularProgress } from '@mui/joy';

import { NotificationItem } from './notificationItem';

const SettingNotifications = () => {
  const navigate = useNavigate();
  const { notificationTemplateStore } = useStores();
  const process = useProcess();
  const notificationTemplates = notificationTemplateStore.toArray();

  if (!process) {
    return (
      <LoaderContainer>
        <LoaderBox>
          <CircularProgress />
        </LoaderBox>
      </LoaderContainer>
    );
  }

  return (
    <SettingsContainer>
      <SettingHeader
        title="Notifications"
        description="Create and customize Trace email template"
      />
      <SettingsContent>
        <div className="title-body">
          <h3>Template(s)</h3>
          <ActionButton
            value="Add a template"
            onClick={() => navigate('new')}
          />
        </div>
        <div className="flex flex-col gap-4 mt-5">
          {notificationTemplates?.map((notif) => (
            <NotificationItem
              key={notif.id}
              id={notif.id}
              title={notif.title}
              updateAt={notif.updatedAt}
            />
          ))}
        </div>
      </SettingsContent>
    </SettingsContainer>
  );
};

export default observer(SettingNotifications);
